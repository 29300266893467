import { Injectable, inject, signal, computed } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PlatformGraphQlService } from '../graphql/platform-graphql.service';
import { CORRECTED_PHONE_NUMBERS } from 'ngx-unificator/helpers';
import { EnvironmentService } from '../environment.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';
import { NgModel } from '@angular/forms';

export enum PhoneStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}

export interface PhoneState {
  isHasVerified: boolean;
  phonePrefix: string;
  phoneMask: string;
  isDynamicShowMaskTyped: boolean;
  dynamicPhoneMask: string;
  replacedPhoneMaskLength: number;
}

interface IMask {
  country: string;
  mask: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserPhoneService {
  private _platformApi = inject(PlatformGraphQlService);
  private _env = inject(EnvironmentService);

  public state = computed(() => this._state());

  private _state = signal<PhoneState>({
    isHasVerified: false,
    phonePrefix: '',
    phoneMask: '',
    isDynamicShowMaskTyped: true,
    replacedPhoneMaskLength: 0,
    dynamicPhoneMask: '',
  });

  private _hasVerifiedPhone: boolean;

  public prefix: string;

  public mask: IMask;

  public get verified(): boolean {
    return this._hasVerifiedPhone;
  }

  /**
   * Returns list of player phone numbers
   */
  getPhoneList(): Observable<any> {
    // return this._api.playerPhone().pipe(
    //   tap(list => this._hasVerifiedPhone = list.some(phone => phone.verified_status === PhoneStatus.VERIFIED)),
    // );
    return of([]);
  }

  /**
   * Add user phone
   *
   * @param data
   */
  addPhone(data: any): Observable<any> {
    return this._platformApi.updateUserPhone(data);
  }

  /**
   * Delete user phone
   */
  deletePhone(): Observable<any> {
    return this._platformApi.updateUserPhone(' ');
  }

  private _updatePrefixStore(callingCode: string) {
    this._state.update(store => {
      return {
        ...store,
        phonePrefix: `+${callingCode}`,
      };
    });
  }

  public updateMainEntities(callingCode: string, short: string) {
    this._updatePrefixStore(callingCode);
    this._updateMaskStore(short);
    this._updateDynamicMaskStore();
    this._updateReplacePhoneMask();
  }

  public updatePhoneStoreMask() {
    this._env.env$
      .pipe(
        tap(({ data }) => {
          this.updateMainEntities(data?.country?.callingCode, data?.country?.short);
        }),
      )
      .subscribe();
  }

  public updateMask(value: string) {
    if (value?.length >= this.state().replacedPhoneMaskLength) {
      this._updateDynamicPhoneMask(this.state().phoneMask + '00');
      this._updateDynamicMaskState(false);
    } else {
      this._updateDynamicPhoneMask(this.state().phoneMask);
      this._updateDynamicMaskState(true);
    }
  }

  public handlePaste(event: ClipboardEvent, inputModel: NgModel) {
    event.preventDefault();
    const pastedText: any = event.clipboardData?.getData('text') || '';

    if (pastedText.length >= this.state().replacedPhoneMaskLength) {
      this._updateDynamicPhoneMask(this.state().phoneMask + '00');
      this._updateDynamicMaskState(false);
    }
    inputModel.control.setValue(pastedText, { emitEvent: true });
  }

  private _updateDynamicMaskStore() {
    this._state.update(store => {
      return {
        ...store,
        dynamicPhoneMask: store.phoneMask,
      };
    });
  }

  private _updateReplacePhoneMask() {
    this._state.update(store => {
      return {
        ...store,
        replacedPhoneMaskLength: store.phoneMask.replace(/ /g, '').length,
      };
    });
  }

  private _updateDynamicPhoneMask(dynamicPhoneMask: string) {
    this._state.update(store => {
      return {
        ...store,
        dynamicPhoneMask,
      };
    });
  }

  private _updateDynamicMaskState(isDynamicShowMaskTyped: boolean) {
    this._state.update(store => {
      return {
        ...store,
        isDynamicShowMaskTyped,
      };
    });
  }

  private _updateMaskStore(country: string) {
    this._state.update(store => {
      return {
        ...store,
        phoneMask: this._getPhoneExampleMask(country),
      };
    });
  }

  private _getPhoneExampleMask(country: any): string {
    const exampleNumber = CORRECTED_PHONE_NUMBERS[country?.toUpperCase()];
    const strippedNumber = exampleNumber.replace(`${this.state().phonePrefix}`, '').trim();
    return `${strippedNumber.replace(/\d/g, '0')}`;
  }

  /**
   * Verify player [hone
   *
   * @param code
   */
  verifyPhone(code: string): Observable<any> {
    // return this._api.playerPhoneConfirm({
    //   phone: {
    //     code
    //   }
    // });
    return of(true);
  }
}
